import { useUser } from "@clerk/clerk-react";
import { useCallback, useEffect, useState } from "react";

import { TrialDays } from "../constants";
import { AppInnerStates, useAppContext } from "../state/AppContext";
import { useApi } from "./useApi";

export type ChatAccess = {
	hasAccessToChat: boolean;
	isFreeTrialActive: boolean;
	isSubscriptionActive: boolean;
	daysAfterRegistration: number;
	openEarlyModal: () => void;
	closeEarlyModal: () => void;
};

export const useChatAccess = (): ChatAccess => {
	const { user } = useUser();
	const { dispatch } = useAppContext();
	const { Subscription: { revalidateUserSubscription } = {} } = useApi("useChatAccess");

	const [isRevalidatingUser, setIsRevalidatingUser] = useState(true);

	// Initialize variables
	const createdAt = user?.createdAt;
	const daysAfterRegistration = createdAt ? Math.floor((new Date().getTime() - new Date(createdAt).getTime()) / (1000 * 60 * 60 * 24)) : 0;
	const isFreeTrialActive = daysAfterRegistration <= TrialDays;

	const checkSubscriptionActive = useCallback(() => {
		return Boolean(user?.publicMetadata?.isSubscriptionActive);
	}, [user]);

	// Function to revalidate user
	const revalidateUser = useCallback(async () => {
		if (!createdAt || !isRevalidatingUser || !revalidateUserSubscription) return; // Exit early if conditions are not met
		setIsRevalidatingUser(false);
		await revalidateUserSubscription();
		await user?.reload();
	}, [createdAt, isRevalidatingUser, revalidateUserSubscription, user]);

	useEffect(() => {
		if (!createdAt) return; // Early exit if createdAt is undefined

		revalidateUser();

		if (!checkSubscriptionActive() && !isFreeTrialActive) {
			dispatch({ type: AppInnerStates.SET_MODAL, payload: "PaymentInfo" });
		}
	}, [createdAt, revalidateUser, checkSubscriptionActive, isFreeTrialActive, dispatch]);

	const openEarlyModal = useCallback(() => {
		dispatch({ type: AppInnerStates.SET_MODAL, payload: "PaymentInfo" });
	}, [dispatch]);

	const closeEarlyModal = useCallback(() => {
		dispatch({ type: AppInnerStates.SET_MODAL, payload: "None" });
	}, [dispatch]);

	// Return default values if createdAt is undefined
	if (!createdAt) {
		return {
			isSubscriptionActive: false,
			hasAccessToChat: false,
			isFreeTrialActive: false,
			daysAfterRegistration: 0,
			openEarlyModal: () => {},
			closeEarlyModal: () => {},
		};
	}

	return {
		hasAccessToChat: checkSubscriptionActive() || isFreeTrialActive,
		isFreeTrialActive,
		isSubscriptionActive: checkSubscriptionActive(),
		daysAfterRegistration,
		openEarlyModal,
		closeEarlyModal,
	};
};
